<template>
	<Fragment>
		<div class="section">
			<h3 class="title mb-20">
				회사 법인정보 및 세금계산서 발급 주소 확인
				<button class="line mini" @click="goToCompanyInfoPage">회사 정보 관리로 이동</button>
			</h3>
			<div class="contents">
				<div class="group column vertical-form condensed">
					<div class="group row">
						<label class="form-label">
							<span class="txt">상호(법인명)</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.corporateName }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">대표자명</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.ceoName }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">대표번호</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.repNumber }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">사업자등록번호</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.businessLicenseNum }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">업태</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.businessCondition }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">종목</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.businessType }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">사업장 주소</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.companyAddress }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">세금계산서 담당자명</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.issuedBillName }}</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-label">
							<span class="txt">이메일</span>
						</label>
						<div class="form-value">
							<span class="txt">{{ origin.issuedBillEmail }}</span>
						</div>
					</div>
					<template v-for="(item, index) in newlyAddedAddonChannel">
						<div
							v-if="
								item.baseInfo.integrationCode === 'ACCOUNT' && $store.getters.getUsingMembership.serviceType === 'SAAS'
							"
							class="group row"
							:class="[
								companyInfoValidClass.addonItem[index].masterId === 'error' ||
								companyInfoValidClass.addonItem[index].masterId === 'empty' ||
								companyInfoValidClass.addonItem[index].masterId === 'needConfirm'
									? 'invalid'
									: companyInfoValidClass.addonItem[index].masterId === 'valid'
									? 'valid'
									: '',
							]"
							:key="'row' + index"
						>
							<label class="form-label">
								<span class="txt">{{ item.baseInfo.name }} 관리자 ID</span>
								<i class="ic-16 ic-info">
									<div class="tooltip-layer left">
										경리회계 경영지원 관리자 페이지에 최초로 접근할 수 있는 계정입니다. 계정 상태가 사용중인
										사이트어드민만 가능하며 생성 이후 관리자 추가와 수정이 가능합니다.
									</div>
								</i>
							</label>
							<div class="form-value">
								<div class="group-inp group row">
									<div class="group-inp-btn small" id="testButtonInput">
										<input type="text" id="testText09" class="" v-model="companyInfo.addonItem[index].masterId" />
										<button class="line" @click="masterIdConfirm(index)">확인</button>
									</div>
								</div>
								<template v-if="companyInfoValidClass.addonItem[index].masterId === 'valid'">
									<p class="text-detail invalid">
										<i class="ic-16 ic-invalid"></i>
										사용할 수 있는 관리자ID 입니다.
									</p>
								</template>
								<template v-if="companyInfoValidClass.addonItem[index].masterId === 'error'">
									<p class="text-detail invalid">
										<i class="ic-16 ic-invalid"></i>
										관리자 ID는 3자 이상, 64자 이하의 영문(소문자), 숫자, -, _, . 만 입력해야 합니다.
									</p>
								</template>
								<template v-if="companyInfoValidClass.addonItem[index].masterId === 'needConfirm'">
									<p class="text-detail invalid">
										<i class="ic-16 ic-invalid"></i>
										관리자 ID는 필수 입력 항목입니다.
									</p>
								</template>
								<template v-if="companyInfoValidClass.addonItem[index].masterId === 'needConfirm'">
									<p class="text-detail invalid">
										<i class="ic-16 ic-invalid"></i>
										입력하신 관리자ID에 대한 [확인]이 필요합니다.
									</p>
								</template>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

import ToastComponent from '@/components/code/modal/ToastComponent.vue';
import { validation } from '@/components/apply/mixinsValidation.js';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
export default {
	mixins: [validation, mixinsErrCodeObserver],
	props: {
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Fragment,
	},
	data() {
		const customer = this.$store.getters.getUsingMembership.customer;
		const origin = {
			corporateName: null,
			ceoName: null,
			businessLicenseNum: null,
			companyAddress: null,
			repNumber: null, // 대표번호
			companyZipCode: null,
			issuedBillName: null,
			issuedBillEmail: null,
			businessLicenses: [],
			businessCondition: null,
			businessType: null,
		};
		origin.corporateName = customer.companyInfo.corporateName ? customer.companyInfo.corporateName : null; // 상호
		origin.ceoName = customer.companyInfo.ceoName ? customer.companyInfo.ceoName : null; // 대표자명
		origin.businessLicenseNum = customer.companyInfo.businessLicenseNum
			? customer.companyInfo.businessLicenseNum
			: null; // 사업자등록번호

		origin.companyAddress = customer.companyInfo.companyAddress ? customer.companyInfo.companyAddress : null; // 사업장 주소
		origin.repNumber = customer.companyInfo.repNumber ? customer.companyInfo.repNumber : null;
		origin.companyZipCode = customer.companyInfo.zipCode ? customer.companyInfo.zipCode : null; // 우편번호
		origin.businessCondition = customer.companyInfo.businessCondition ? customer.companyInfo.businessCondition : null; // 업태
		origin.businessType = customer.companyInfo.businessType ? customer.companyInfo.businessType : null; // 종목

		origin.issuedBillName = customer.companyInfo.taxInvoiceManager.taxInvoiceManagerName
			? customer.companyInfo.taxInvoiceManager.taxInvoiceManagerName
			: null; // 세금계산서 담당자 이름
		origin.issuedBillEmail = customer.companyInfo.taxInvoiceManager.taxInvoiceEmail
			? customer.companyInfo.taxInvoiceManager.taxInvoiceEmail
			: null; // 세금계산서 담당자 이메일 */

		origin.businessLicenses = customer.companyInfo.businessLicenses; // 사업자 등록증
		if (origin.businessLicenses.length > 0) {
			this.$store.commit('SET_BUSINESS_REGISTRATION', origin.businessLicenses);
		}
		// 신규 추가 부가서비스
		var newlyAddedAddonChannel = this.$store.getters.getAddonChannel.filter((obj) => obj.baseInfo.usingCount == 0);
		var addonItemData = [];
		var addonItemValid = [];
		if (newlyAddedAddonChannel.length > 0) {
			newlyAddedAddonChannel.forEach((data) => {
				addonItemData.push({
					code: data.baseInfo.code,
					name: data.baseInfo.name,
					integrationCode: data.baseInfo.integrationCode,
					masterId: '',
					url: '',
				});
				addonItemValid.push({
					name: data.baseInfo.name,
					integrationCode: data.baseInfo.integrationCode,
					masterId: '',
					url: '',
				});
			});
		}

		return {
			toastOpen: false,
			newlyAddedAddonChannel,
			erpSyncStatus: false,
			origin,
			companyInfo: {
				corporateName: origin.corporateName, // 필수
				businessLicenseNum: origin.businessLicenses, // 필수
				ceoName: origin.ceoName, // 필수
				directPhone: origin.directPhone || '',
				industrySector: origin.industrySector || '',
				companyZipCode: origin.companyZipCode || '',
				companyAddress: origin.companyAddress, // 필수
				businessCondition: origin.businessCondition || '',
				businessType: origin.businessType || '',
				issuedBillName: origin.issuedBillName, // 필수
				issuedBillEmail: origin.issuedBillEmail, // 필수
				addonItem: addonItemData, // 필수
				confirm: true,
			},
			companyInfoValidClass: {
				erpSyncStatus: false,
				corporateName: 'valid',
				businessLicenseNum: 'valid',
				ceoName: 'valid',
				directPhone: 'valid',
				industrySector: 'valid',
				companyZipCode: 'valid',
				companyAddress: 'valid',
				businessCondition: 'valid',
				businessType: 'valid',
				issuedBillName: 'valid',
				issuedBillEmail: 'valid',
				addonItem: addonItemValid,
			},
			businessInfoConfirm: true,
			activeClass: 'active',
			isBusinessActive: false,
		};
	},
	async created() {
		// const customer = this.getCustomer();
		const origin = this.origin;

		if (origin.corporateName) {
			this.companyInfo.corporateName = origin.corporateName;
			this.companyInfoValidClass.corporateName = 'valid';
		}

		if (origin.businessLicenseNum) {
			this.companyInfo.businessLicenseNum = origin.businessLicenseNum;
			this.companyInfoValidClass.businessLicenseNum = 'valid';
		}

		if (origin.businessLicenses) {
			this.companyInfo.businessLicenses = origin.businessLicenses;
		}

		if (origin.ceoName) {
			this.companyInfo.ceoName = origin.ceoName;
			this.companyInfoValidClass.ceoName = 'valid';
		}
		if (origin.repNumber) {
			this.companyInfo.directPhone = origin.repNumber;
			this.companyInfoValidClass.directPhone = 'valid';
		}
		if (origin.companyZipCode) {
			this.companyInfo.companyZipCode = origin.companyZipCode;
			this.companyInfoValidClass.companyZipCode = 'valid';
		}
		if (origin.companyAddress) {
			this.companyInfo.companyAddress = origin.companyAddress;
			this.companyInfoValidClass.companyAddress = 'valid';
		}
		if (origin.businessCondition) {
			this.companyInfo.businessCondition = origin.businessCondition;
			this.companyInfoValidClass.businessCondition = 'valid';
		}
		if (origin.businessType) {
			this.companyInfo.businessType = origin.businessType;
			this.companyInfoValidClass.businessType = 'valid';
		}
		if (origin.issuedBillName) {
			this.companyInfo.issuedBillName = origin.issuedBillName;
			this.companyInfoValidClass.issuedBillName = 'valid';
		}
		if (origin.issuedBillEmail) {
			this.companyInfo.issuedBillEmail = origin.issuedBillEmail;
			this.companyInfoValidClass.issuedBillEmail = 'valid';
		}

		await this.checkValidInfo();
		await this.saveUserInfo();
	},
	computed: {
		containsNewAccountAddon() {
			return this.newlyAddedAddonChannel.findIndex((newAddon) => newAddon.baseInfo.integrationCode === 'ACCOUNT') > -1;
		},
	},
	methods: {
		async masterIdConfirm(idx) {
			try {
				let validClass = 'valid';
				const baseChannelInfo = this.$store.getters.getUsingMembership.representativeGroupware;
				const masterId = this.companyInfo.addonItem[idx].masterId;
				const { data } = await this.$store.dispatch('confirmAdminId', {
					uuid: baseChannelInfo.uuid,
					email: `${masterId}@${baseChannelInfo.domain}`,
				});

				if (!data) {
					validClass = 'needConfirm';
				}

				this.companyInfoValidClass.addonItem[idx].masterId = validClass;
			} catch (e) {
				this.observeErrCode(e.data?.errorCode, e.data?.errorMessage, this.$toasted.show);
				this.companyInfoValidClass.addonItem[idx].masterId = 'needConfirm';
			}
		},
		async getCustomer() {
			// customer에 erpSyncStatus가 없기 때문에 현재 메서드를 호출하지 않음
			const customer = await this.$store.dispatch('fetchCustomerInfo');
			this.erpSyncStatus = customer.data?.erpSyncStatus === 'DONE';
			this.companyInfoValidClass.erpSyncStatus = this.erpSyncStatus;
			return customer;
		},
		async checkValidInfo() {
			for (const idx in Object.keys(this.companyInfo)) {
				const key = Object.keys(this.companyInfo)[idx];
				const value = this.companyInfo[key];
				if (key !== 'companyZipCode' && key !== 'addonItem') {
					if (!value || value.length == 0) {
						this.companyInfoValidClass[key] = 'empty';
					}
				}
			}

			if (this.companyInfo.addonItem.length > 0) {
				for (let i = 0; i < this.companyInfo.addonItem.length; i++) {
					const addon = this.companyInfo.addonItem[i];
					const addonValidClass = this.companyInfoValidClass.addonItem[i];

					if (addon.integrationCode !== 'ACCOUNT') {
						continue;
					}

					if (addon.masterId == '') {
						addonValidClass.masterId = 'empty';
					}

					if (addonValidClass.masterId == '' || addonValidClass.masterId == 'empty' || addon.masterId == '') {
						addonValidClass.masterId = 'needConfirm';
					}
				}
			}
			return;
		},
		checkUrl(data) {
			if (data.length < 1 || data.length > 64) {
				return false;
			}

			var url = data;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(url.replace(/[\\_.-]/g, ''))) {
				return false;
			}
			// 중복체크
			return true;
		},
		removeEmpty(val) {
			return val.replace(/\s+/, '').replace(/\s+$/g, '').replace(/\n/g, '').replace(/\r/g, '');
		},
		async saveUserInfo() {
			await this.$emit('setNewSiteInfo', {
				companyInfoValidClass: this.companyInfoValidClass,
				companyInfo: this.companyInfo,
			});
		},
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
		goToCompanyInfoPage() {
			this.$router.push({ name: 'CompanyInfoMain' });
		},
	},
};
</script>

<style></style>
