<template>
	<Fragment>
		<div class="container">
			<div class="body-contents payment">
				<h2 class="heading-1-b">추가/연장주문</h2>
				<ol class="stepper type2">
					<li class="step on">
						<a href="#" title="step01">
							<i>1</i>
							<span>서비스 및 상품 선택</span>
						</a>
					</li>
					<li class="step on">
						<a href="#" title="step02">
							<i>2</i>
							<span>약관 동의 및 결제</span>
						</a>
					</li>
					<li class="step">
						<a href="#" title="step03">
							<i>3</i>
							<span>주문 완료</span>
						</a>
					</li>
				</ol>
				<div class="section-wrap gap64">
					<company-site-info v-on:setNewSiteInfo="setNewSiteInfo"></company-site-info>
					<order-info></order-info>
					<div class="section" v-if="usePayMethod">
						<h3 class="title">결제 방법 선택</h3>
						<div class="contents">
							<pay-method
								:saveFlag="save"
								:orderType="'ADDEXTENSION'"
								:fixedPayMethod="usePayMethod ? '' : 'deposit'"
								v-on:setPayMethodInfo="setPayMethodInfo"
							></pay-method>
						</div>
					</div>
					<!--					<div class="section" v-if="addAccountAddon">-->
					<div class="section" v-if="true">
						<h3 class="title">경리회계 약관 및 이용 동의</h3>
						<div class="contents">
							<div class="">
								<div class="group column vertical-form basic">
									<!--									<div class="group row border-bottom">-->
									<!--										<div class="group-chk large">-->
									<!--											<input type="checkbox" />-->
									<!--											<label class="label">경리회계 약관 및 이용 동의 (전체)</label>-->
									<!--										</div>-->
									<!--									</div>-->
									<div class="group column gap8">
										<div class="group row">
											<div
												class="group-chk"
												@click="
													() => {
														this.accountApprove = !this.accountApprove;
													}
												"
											>
												<input type="checkbox" v-model="accountApprove" />
												<label class="label"> 개인 정보 수집 및 이용에 동의합니다. (필수) </label>
											</div>
											<a class="text-link" v-on:click="openTermsLayer">내용보기</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<step-footer
						class="wrap_function"
						:disable="disable"
						:stepBtnInfo="stepBtnInfo"
						:actionBeforeMove="saveInput"
					></step-footer>
					<div v-if="toastOpen" class="toast" style="left: 28%; top: 80%; z-index: 9998">
						<h1>
							주문 생성중입니다. 잠시만 기다려주세요.
							<span class="ic_set ic_sync"></span>
						</h1>
						<h1>( 완료 후, 자동으로 결제창 실행 또는 페이지 이동 됩니다. )</h1>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import OrderInfo from '@/components/order/OrderInfo.vue';
import PayMethod from '@/components/payment/method/PayMethod.vue';
import CompanySiteInfo from '@/components/apply/user/store/CompanySiteInfo.vue';
import StepFooter from '@/components/common/StepFooter.vue';
import ToastComponent from '@/components/code/modal/ToastComponent.vue';
import mixinsAlert from '@/components/common/mixinsAlert';
import { payment } from '@/components/apply/mixinsPayment.js';
import { order } from '@/components/apply/mixinsOrder.js';
import { validation } from '@/components/apply/mixinsValidation.js';
import termsTemplate from '@/components/apply/AccountTerms.vue';
import { TERMS_MODAL_STYLE } from '@/utils/constants';
export default {
	mixins: [payment, order, validation, mixinsAlert],
	components: {
		OrderInfo,
		PayMethod,
		CompanySiteInfo,
		StepFooter,
	},
	data() {
		return {
			termsCnt: 0,
			disable: false,
			toastOpen: false,
			stepBtnInfo: null,
			paymentUrl: '/kiwoom/popup',
			enablePopup: false,
			input: {
				newSiteInfo: null,
				payMethodInfo: null,
				termsAgreementInfo: null,
			},
			save: false,
			payMethodInfo: {},
			addExtensionOrder: null,
			addAccountAddon: false, //추가주문으로 경리회계가 있는지 여부(임시)
			accountApprove: false, //경리회계 약관 체크
		};
	},
	created() {
		this.stepBtnInfo = {
			next: this.usePayMethod ? '결제하기' : '신청하기',
			prevLink: '/order/addExtension',
		};
		if (!this.usePayMethod) {
			this.input.payMethodInfo = { payType: 'DEPOSIT', infoConsent: true };
		}
		//경리회계가 추가로 구매되었는지
		this.checkAccountAdd();
	},
	computed: {
		usePayMethod() {
			return this.$store.getters.getAddExtensionPrices.finalPayPrice === 0 ? false : true;
		},
	},
	watch: {
		async 'input.newSiteInfo'() {
			this.validateAndApply();
		},
		async 'input.payMethodInfo'() {
			this.validateAndApply();
		},
		async 'input.termsAgreementInfo'() {
			this.validateAndApply();
		},
	},
	methods: {
		async validateAndApply() {
			if (
				this.input.newSiteInfo &&
				(!this.usePayMethod || this.input.payMethodInfo) &&
				(this.termsCnt == 0 || this.input.termsAgreementInfo)
			) {
				if (!(await this.dataValidation())) {
					window.scrollTo(0, 0);
				}
				this.save = false;
				this.input.payMethodInfo = null;
				this.input.termsAgreementInfo = null;
			}
		},
		checkAccountAdd() {
			const addon = this.$store.getters.getAddonChannel;
			const accountAddon = addon.filter((o) => o.baseInfo.name.includes('경리회계'));
			if (accountAddon.length && accountAddon[0].add.length > 0) {
				this.addAccountAddon = true;
			}
		},
		openTermsLayer() {
			this.$modal.show(
				termsTemplate,
				{
					info: this.accountApprove,
				},
				Object.assign({}, TERMS_MODAL_STYLE, { name: 'termsTemplate', clickToClose: false }),
			);
		},
		close() {
			this.accountApprove = this.$store.getters.getAccountAgreeTerm;
			this.$store.commit('INIT_ACCOUNT_AGREE_TERM');
		},
		async checkTermsCnt(cnt) {
			this.termsCnt = cnt;
		},
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
		closePopup() {
			this.enablePopup = false;
			this.saveFlag = false;
		},
		saveInput() {
			this.save = !this.save;
		},
		async apply() {
			// 주문 생성 & 결제
			this.toastOpen = true;
			this.disable = true;
			await this.updateCustomer();
			this.addExtensionOrder = await this.createAddExtensionOrder();

			if (!this.addExtensionOrder) {
				this.toastOpen = false;
				if (this.addExtensionOrder == null) {
					this.alert({
						title: '시스템 에러 발생',
						contents: '주문 생성 중, 시스템 에러가 발생하였습니다. 시스템 담당자에게 문의해주세요.',
					});
				}
				return;
			}
			this.payMethodInfo['amount'] = this.$store.getters.getAddExtensionPrices.finalPayPrice;
			this.payMethodInfo['orderId'] = this.addExtensionOrder.id;
			this.payMethodInfo['orderSummary'] = this.addExtensionOrder.orderSummary
				? this.addExtensionOrder.orderSummary
				: ' ';
			this.payMethodInfo['corporateName'] = this.addExtensionOrder.customer.companyInfo.corporateName;
			this.payMethodInfo['serviceType'] = this.addExtensionOrder.serviceType;

			await localStorage.setItem('payMethodInfo', JSON.stringify(this.payMethodInfo));
			this.disable = false;

			if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
				// 자동 결제 API
				await this.saveAutoExtensionInfo(this.addExtensionOrder);
			} else {
				await this.goNext();
			}
			this.toastOpen = false;
		},
		async goNext() {
			if (this.payMethodInfo.payType == 'DEPOSIT' || this.payMethodInfo.payType == 'AUTOEXTENSION') {
				await this.goNextPage('/order/complete');
			} else {
				await this.goPayment(this.payMethodInfo, '/order/complete');
			}
		},
		async updateCustomer() {
			try {
				const usingMembership = this.$store.getters.getUsingMembership;
				// 1. customer(고객사 정보)
				const customer = usingMembership.customer; // 회사정보
				const allCompanyInfo = await this.$store.getters.getNewSiteInfo;
				const companyInfo = {
					corporateName: allCompanyInfo.companyInfo.corporateName,
					businessLicenseNum: allCompanyInfo.companyInfo.businessLicenseNum,
					ceoName: allCompanyInfo.companyInfo.ceoName,
					companyAddress: allCompanyInfo.companyInfo.companyAddress,
					zipCode: allCompanyInfo.companyInfo.companyZipCode,
					repNumber: allCompanyInfo.companyInfo.directPhone,
					industryCategory: allCompanyInfo.companyInfo.industrySector ? allCompanyInfo.companyInfo.industrySector : '',
					businessCondition: allCompanyInfo.companyInfo.businessCondition
						? allCompanyInfo.companyInfo.businessCondition
						: '',
					businessType: allCompanyInfo.companyInfo.businessType ? allCompanyInfo.companyInfo.businessType : '',
					taxInvoiceManager: {
						taxInvoiceManagerName: allCompanyInfo.companyInfo.issuedBillName,
						taxInvoiceEmail: allCompanyInfo.companyInfo.issuedBillEmail,
					},
					businessLicenses: await this.$store.getters.getBusinessRegistration,
					businessNum: allCompanyInfo.companyInfo.businessNum,
				}; // 회사정보
				customer.companyInfo = companyInfo;
				await this.$store.dispatch('updateCustomer', customer);
			} catch (e) {
				console.log(e);
			}
		},
		async createAddExtensionOrder() {
			try {
				var param = await this.makeOrderData();
				let payType = '';
				if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
					payType = 'AUTO_CREDITCARD';
				} else if (this.payMethodInfo.payType == 'CREDITCARD') {
					payType = 'CREDITCARD';
				} else if (this.payMethodInfo.payType == 'DEPOSIT') {
					payType = 'TRANSPORT';
				} else if (this.payMethodInfo.payType == 'REALTIMETRANSPORT') {
					payType = 'REALTIME_TRANSPORT';
				}
				param.payType = payType;

				let res;
				let errorResponse;
				let cnt = 0;
				let errorFlag = false;
				while (!res && !errorFlag && cnt <= 10) {
					cnt++;
					try {
						res = await this.$store.dispatch('createNewOrderInfo', param);
						if (res.error) {
							errorFlag = true;
						}
					} catch (error) {
						if (error) {
							errorFlag = true;
						}
						errorResponse = error;
					}
				}
				if (errorFlag || cnt > 10) {
					const errorTitle = '주문 생성 실패';
					const errorMessage =
						cnt <= 10 && errorResponse.status == '400' && errorResponse.data.errorMessage
							? errorResponse.data.errorMessage
							: '주문 생성 중, 시스템 에러가 발생하였습니다. 시스템 담당자에게 문의해주세요.';
					this.alert({
						title: errorTitle,
						contents: errorMessage,
					});
					return false;
				} else {
					return res.data;
				}
			} catch (error) {
				return null;
			}
		},
		async saveAutoExtensionInfo(data) {
			const result = await this.setAutoExtension(this.payMethodInfo, data.id, data.customer.id, data.serviceType); // 자동결제 등록

			if (result) {
				await this.goNextPage('/order/complete');
			}
		},
		async applyOrder(data) {
			await this.$store.dispatch('applyNewOrderInfo', data); // 입금확인
		},
		async dataValidation() {
			// 순서대로 사이트 -> 결제 -> 약관
			const newSiteInfo = this.input.newSiteInfo;
			const companyInfoValidClass = this.input.newSiteInfo.companyInfoValidClass;
			const termsAgreementInfo = this.input.termsAgreementInfo;

			//상호명
			if (companyInfoValidClass.corporateName !== 'valid') {
				return false;
			}

			//사업자등록번호
			if (companyInfoValidClass.businessLicenseNum !== 'valid') {
				return false;
			}

			//대표자명
			if (companyInfoValidClass.ceoName !== 'valid') {
				return false;
			}

			//사업장 주소
			if (companyInfoValidClass.companyAddress !== 'valid') {
				return false;
			}

			//업태
			if (companyInfoValidClass.businessCondition !== 'valid') {
				return false;
			}
			//종목
			if (companyInfoValidClass.businessType !== 'valid') {
				return false;
			}

			//부가서비스 관리자 ID 필수체크 (경리회계 외의 부가서비스도 담당자 ID가 필요한 경우 필터 조건 수정 필요)
			const isValidAddonMasterId = companyInfoValidClass.addonItem
				.filter((o) => o.integrationCode === 'ACCOUNT')
				.every((o) => o.masterId === 'valid');
			if (!isValidAddonMasterId && this.$store.getters.getUsingMembership.serviceType === 'SAAS') {
				return false;
			}

			if (!newSiteInfo.companyInfo.confirm) {
				this.openToast('사업자진위 확인을 해주세요.');
				return true;
			}

			const payMethodInfo = this.input.payMethodInfo;
			// 결제방법 체크
			if (payMethodInfo.payType == 'AUTOEXTENSION') {
				if (!this.checkAutoExtension(payMethodInfo)) {
					return false;
				}
			}

			if (
				(payMethodInfo.payType == 'DEPOSIT' || payMethodInfo.payType == 'REALTIMETRANSPORT') &&
				payMethodInfo.issuedBill
			) {
				const businessRegistration = this.$store.getters.getBusinessRegistration;
				if (businessRegistration.length == 0) {
					this.openToast('세금계산서 신청시 사업자등록증은 필수입니다.');
					return true;
				}
			}

			if (!payMethodInfo.infoConsent) {
				this.openToast('결제 방법 안내 동의가 필요합니다.');
				return true;
			}

			await this.$store.commit('SET_PAY_METHOD_INFO', payMethodInfo);
			this.payMethodInfo = payMethodInfo;

			// 약관 동의 체크
			for (let idx in termsAgreementInfo) {
				const terms = termsAgreementInfo[idx];
				if (terms.required && !terms.approved) {
					this.openToast('[' + terms.termsName + '] 약관은 필수 동의 항목입니다.');
					return true;
				}
			}

			//경리회계 동의체크(임시)
			if (this.addAccountAddon) {
				if (!this.accountApprove) {
					this.openToast('경리회계 약관 및 이용동의가 필요합니다.');
					return true;
				}
			}

			await this.$store.commit('SET_NEW_SITE_INFO', newSiteInfo);
			await this.$store.commit('SET_TERMS_AGREEMENT_INFO', termsAgreementInfo);
			await this.apply();
			return true;
		},
		async setNewSiteInfo(param) {
			this.input.newSiteInfo = param;
		},
		async setPayMethodInfo(payMethodInfo) {
			this.input.payMethodInfo = payMethodInfo;
		},
		async setTermsAgreement(termsAgreementInfo) {
			this.input.termsAgreementInfo = termsAgreementInfo;
		},
		getServiceType() {
			const usingMembership = this.$store.getters.getUsingMembership;
			return usingMembership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType;
		},
	},
};
</script>

<style scoped>
#payPopup {
	/*
         높이 넓이를 자식창에 맞도록 변경
         height 값의 경우 100vh가 맞지만 top: -150px 을 해주었기 때문에 비율 만큼 더했다!
         */
	height: 116vh;
	width: 100vw;
	z-index: 101;
	top: 300px;
	left: 800px;
	/*상단 숨김 코드*/
}
.animate {
	text-align: center;
	opacity: 0.5;
	animation: pulse 1s infinite;
	animation-delay: 1s;
	display: inline-block;
}
</style>
